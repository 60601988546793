<template>
  <v-container class="layout-container">
    <v-card class="text-center ma-16 pa-16">
      <ProgressCircular />
      <h2>{{ $t('linkedin.process-authorization') }}</h2>
    </v-card>
  </v-container>
</template>

<script>
import ProgressCircular from '@/components/ProgressCircular.vue'

export default {
  components: { ProgressCircular },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const error = urlParams.get('error')
    if (error) {
      window.opener.postMessage({
        linkedinConnectionError: {
          error,
          errorDescription: urlParams.get('error_description')
        }
      }, '*')
      window.close()
      return
    }
    const code = urlParams.get('code')

    // Send the authorization code back to the original window
    if (code) {
      window.opener.postMessage({ linkedinConnectionCode: code }, '*')
      window.close()
    }
  }
}
</script>
